class Endpoints {
  // static BASE_URL = "https://backend.wtdapi.dev/";
  static MODE = 'prod';

  // users endpoints

  static LOGIN_URL = "api/accounts/login/";
  static SEND_OTP_URL = "accounts/otp/";
  static ORDER = "order/";
  static PAYMENT = "payment/";
  static MERCHANT = "merchant/";
  static MERCHANTS = "merchants/";

  static VERIFY_TOKEN = "auth/verify-otp-token";

  static PROFILE = "users/";
  static ACCOUNTS = "api/accounts/";
  static ROLE = "role/";
  static BRANCH = "branches/";

  static LOGOUT = "auth/logout";

  // snippets endpoints

  static START_SNIPPET = "active-snippet/start";

  static ACTIVE_SNIPPET = "active-snippet";

  // invoice endpoints

  static FETCH_INVOICE = "reports/payments";
}

export default Endpoints;
